// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-chapter-1-js": () => import("/opt/build/repo/src/pages/chapter-1.js" /* webpackChunkName: "component---src-pages-chapter-1-js" */),
  "component---src-pages-chapter-2-js": () => import("/opt/build/repo/src/pages/chapter-2.js" /* webpackChunkName: "component---src-pages-chapter-2-js" */),
  "component---src-pages-chapter-3-js": () => import("/opt/build/repo/src/pages/chapter-3.js" /* webpackChunkName: "component---src-pages-chapter-3-js" */),
  "component---src-pages-chapter-4-js": () => import("/opt/build/repo/src/pages/chapter-4.js" /* webpackChunkName: "component---src-pages-chapter-4-js" */),
  "component---src-pages-chapter-5-js": () => import("/opt/build/repo/src/pages/chapter-5.js" /* webpackChunkName: "component---src-pages-chapter-5-js" */),
  "component---src-pages-chapter-6-js": () => import("/opt/build/repo/src/pages/chapter-6.js" /* webpackChunkName: "component---src-pages-chapter-6-js" */),
  "component---src-pages-chapter-7-js": () => import("/opt/build/repo/src/pages/chapter-7.js" /* webpackChunkName: "component---src-pages-chapter-7-js" */),
  "component---src-pages-conclusion-js": () => import("/opt/build/repo/src/pages/conclusion.js" /* webpackChunkName: "component---src-pages-conclusion-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-introduction-js": () => import("/opt/build/repo/src/pages/introduction.js" /* webpackChunkName: "component---src-pages-introduction-js" */)
}

